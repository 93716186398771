@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Noto+Sans+KR&display=swap");

:root {
  font-size: 17px;
  color: #222;
  font-family: "Lato", sans-serif;
  user-select: none;
}

@media (width <= 768px) {
  :root {
    font-size: 17px;
  }
}
